<template>
	<v-container fluid class="pb-0" style="max-width: 1200px">
		<v-row>
			<v-col cols="12" md="4" class="d-flex flex-column" v-if="(!$route.params.id && $vuetify.breakpoint.smAndDown) || $vuetify.breakpoint.mdAndUp">
				<v-toolbar rounded="t-xl">
					<v-toolbar-title>
						<span class="text-h5">
							{{ $t('nav.messages') }}
						</span>
						<br />
						<span class="text-caption">
							{{ $t('chats.recentChats') }}
						</span>
					</v-toolbar-title>
				</v-toolbar>

				<v-card rounded="b-xl" class="scrollable" :style="{ height: chatHeight }">
					<v-fade-transition group hide-on-leave>
						<template v-if="loading">
							<v-skeleton-loader v-for="i in 10" :key="i" type="list-item-avatar-two-line" class="mx-auto mb-1 rounded-xl" max-width="600" />
						</template>

						<template v-else-if="chats.length">
							<v-list subheader two-line key="itemsData" rounded class="pa-0">
								<ChatsListItem v-for="chat in filteredChats" :key="chat.id" :chat="chat" />
							</v-list>

							<template v-if="!filteredChats.length">
								<h4 class="m-4 text-center" key="noResultsCriteria">
									{{ $t('search.noResultsCriteria') }}
								</h4>
							</template>
						</template>

						<template v-else>
							<div key="noItemsData" class="mx-auto">
								<div class="mt-2 mb-5 text-center">
									<v-icon size="48" role="img">
										mdi-package-variant
									</v-icon>
									<br />
									<span class="text-h6 font-weight-bold">{{ $t('search.empty') }}</span>
								</div>
								<h4 class="m-4 text-center">
									{{ $t('chats.noChats') }}
								</h4>
							</div>
						</template>
					</v-fade-transition>
				</v-card>
			</v-col>

			<v-col cols="12" md="8">
				<router-view />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

// generic accent removal from input string, add any missing characters
function removeAccents(input) {
	if (!input) return ''
	const letters1 = 'äáàâăëéèêĕíìüúùûŭöóòôŏÄÁÀÂĂËÉÈÊĔÜÚÙÛŬÖÓÒÔŎßșȘ'
	const letters2 = 'aaaaaeeeeeiiuuuuuoooooAAAAAEEEEEUUUUUOOOOOssS'
	const patternLetters = new RegExp('[' + letters1 + ']', 'g')
	const lookupLetters = {}
	letters1.split('').forEach(function(letter, i) {
		lookupLetters[letter] = letters2[i]
	})
	const letterTranslator = function(match) {
		return lookupLetters[match] || match
	}
	return input.replace(patternLetters, letterTranslator)
}

export default {
	metaInfo() {
		return {
			title: this.$t('nav.messages')
		}
	},
	data() {
		return {
			loading: true,
			search: ''
		}
	},
	components: {
		ChatsListItem: () => import('@/components/chats/ChatsListItem.vue')
	},
	computed: {
		filteredChats() {
			return this.chats.filter(
				(chat) =>
					removeAccents(chat.application ? chat.application.fullname : '')
						.toLowerCase()
						.includes(removeAccents(this.search).toLowerCase()) ||
					removeAccents(chat.attendance ? chat.attendance.fullname : '')
						.toLowerCase()
						.includes(removeAccents(this.search).toLowerCase()) ||
					removeAccents(chat.offer ? chat.offer.title : '')
						.toLowerCase()
						.includes(removeAccents(this.search).toLowerCase()) ||
					removeAccents(chat.event ? chat.event.title : '')
						.toLowerCase()
						.includes(removeAccents(this.search).toLowerCase())
			)
		},
		chatHeight() {
			let x = this.$vuetify.breakpoint.height
			if (x > 2000) return '80vh'
			else if (x < 400) return '10vh'
			else
				return `${-4.25 * Math.pow(10, -11) * Math.pow(x, 4) +
					0.000000265 * Math.pow(x, 3) -
					0.0005776327 * Math.pow(x, 2) +
					0.5488767355 * x -
					117}vh`
		},
		...mapGetters({
			chats: 'chats/chats'
		})
	},
	created() {
		this.fetchChats().then(() => {
			this.loading = false
		})
	},
	methods: {
		...mapActions('chats', ['fetchChats'])
	}
}
</script>

<style scoped>
.scrollable {
	overflow-y: auto;
}
</style>
